import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Background from "../images/Bg6.png"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

class ServicePageEcommerceTemplate extends React.Component {
    render() {
        const ecommercepage = this.props.data.contentfulEcommerce
        let title = ecommercepage.cityName + " Ecommerce Website Development | Ecommerce Website Design"
        let keywords = [ecommercepage.cityName + " Ecommerce website", ecommercepage.cityName + " Ecommerce developer", ecommercepage.cityName + " Ecommerce", ecommercepage.cityName + " website store", ecommercepage.cityName + " Ecommerce website designer"]
        let location = "/" + ecommercepage.slug
        let description = "Pyxel Development offers professional " + ecommercepage.cityName + " Ecommerce Website Design, Ecommerce website development, and Ecommerce website consulting services that are guaranteed to boost your business to the next level at an affordable rate."
        let isProduct = true
        let productName = ecommercepage.cityName + " Ecommerce"
        let sku = "10000003"
        return (
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} isProduct={isProduct} productName={productName} sku={sku} />
                <div className="bg-no-repeat bg-cover bg-left"
                    style={{
                        backgroundImage: `url(${Background})`,
                        width: '100%',
                        height: '100%',
                        backgroundAttachment: 'absolute',
                    }}>
                    <div className="container gap-x-10 grid lg:grid-cols-2 text-white font-bold p-10 pt-36 mx-auto xl:py-40">
                        <div className="flex justify-center lg:justify-end">
                            <div>
                                <p>SERVICE</p>
                                <h1 className="text-5xl md:text-6xl xl:text-7xl font-pixeboy" style={{ lineHeight: "80%" }}>
                                    {ecommercepage.cityName}<br />{ecommercepage.serviceType} Website
                                </h1>
                                <h2 className="uppercase text-md md:text-xl max-w-lg mt-7 uppercase">
                                    Get a Blazing fast and fully custom Ecommerce website using any seller dashboard you want
                                </h2>
                                <div className="max-w-xl font-normal text-xs md:text-base">
                                    <p className="mt-7 md:mt-12">
                                        The biggest difference between building a {ecommercepage.cityName} Ecommerce website using services like Shopify or Wix and services from Pyxel
                                        Development is that we dedicate our focus to guarantee lower bounce rates, higher conversion rates, and an increase in traffic to your {ecommercepage.cityName} Ecommerce
                                        website.<br /><br /> Pyxel Development incorporates industry leading technologies to guarantee blazing fast and SEO friendly Ecommerce websites
                                        to attract new leads and increase your profits. Shopify, Wix, and every other service that offers quick and easy Ecommerce stores lose over 53%
                                        of customers due to slow page speeds and lack of guidance for search engine optimization. Pyxel Development GUARANTEES the success of
                                        your {ecommercepage.cityName} Ecommerce website because we work with you to optimize your website to leave your competition in the dust!
                                    </p>
                                    <div className="hidden lg:block mt-10 md:mt-12 xl:mt-28">
                                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                            <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                                <span>Request Quote</span>
                                            </button>
                                        </Link>
                                        <div className="hover:text-gray-600 mt-4">
                                            <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development" >
                                                Call Us: (916) 821-9044
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center pt-10 lg:pt-0 mx-auto max-w-md lg:max-w-2xl">
                            <StaticImage src="../images/ecommerce.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Website agency"} alt={ecommercepage.cityName + " Ecommerce Website agency"} />
                        </div>
                        <div className="lg:hidden mx-auto mt-10 md:mt-12 xl:mt-28">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                    <span>Request Quote</span>
                                </button>
                            </Link>
                            <div className="hover:text-gray-600 mt-4">
                                <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                    Call Us: (916) 821-9044
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner py-10 md:py-24 px-5 text-white">
                    <div className="container mx-auto">
                        <p className="text-center font-bold text-2xl md:text-4xl uppercase">Grow and Succeed Online</p>
                        <h2 className="text-center mx-auto uppercase text-lg pt-3">{ecommercepage.cityName} Ecommerce Website Agency</h2>
                        <div className="text-center mx-auto max-w-xs py-5 mb-3 md:mt-10 md:grid md:grid-cols-2 xl:grid-cols-4 space-y-8 md:space-y-0 md:gap-16 md:max-w-full">
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/web-design.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Website Design"} alt={ecommercepage.cityName + " Ecommerce Website Design"} />
                                </div>
                                <h3 className="font-bold text-lg py-3 uppercase">Breath-taking Design</h3>
                                <p className="text-sm md:text-base">Custom designs to help make your brand memorable and reach new heights</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2" src="../images/web-design.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Website Design"} alt={ecommercepage.cityName + " Ecommerce Website Design"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/cashless-payment.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Online Payment"} alt={ecommercepage.cityName + " Ecommerce Online Payment"} />
                                </div>
                                <h3 className="font-bold text-lg py-3 uppercase">Accept All Online Payments</h3>
                                <p className="text-sm md:text-base">Securely process credit card payments and ALL other forms of transactions</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2 " src="../images/cashless-payment.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Online Payment"} alt={ecommercepage.cityName + " Ecommerce Online Payment"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/dashboard.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Analytics"} alt={ecommercepage.cityName + " Ecommerce Analytics"} />
                                </div>
                                <h3 className="font-bold text-lg py-3 uppercase">Traffic and Sales Analytics</h3>
                                <p className="text-sm md:text-base">See how well your business is doing compared to your competition</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2" src="../images/dashboard.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Analytics"} alt={ecommercepage.cityName + " Ecommerce Analytics"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/secure-payment.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Secure Shopping"} alt={ecommercepage.cityName + " Ecommerce Secure Shopping"} />
                                </div>
                                <h3 className="font-bold text-lg py-3 uppercase">Secure Shopping</h3>
                                <p className="text-sm md:text-base">Industry leading security and encryption strategies for safe shopping</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2" src="../images/secure-payment.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Secure Shopping"} alt={ecommercepage.cityName + " Ecommerce Secure Shopping"} />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center md:-mt-10">
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="py-10 md:py-24">
                        <div className="px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase mx-auto text-2xl font-bold md:text-4xl">
                                    Top-Rated {ecommercepage.cityName} Ecommerce Website Services
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />Let Pyxel Development help you build your {ecommercepage.cityName} Ecommerce website to reflect the reputation and image of your brand. Leave a long lasting and memorable
                                    impression on your clients to keep them coming back for more! We can integrate ANY seller dashboard of your choice into the Ecommerce websites
                                    that we create to ensure that you are comfortable and happy with your experience.<br /><br /> Keep the same seller dashboard that you love to use all while having
                                    the benefits that Pyxel Development offers, ranging from blazing fast page speeds to optimized SEO, which directly leads to an increase in profits!
                                </p>
                                <p className="pt-10 text-xl md:text-2xl font-bold">Popular seller dashboards we work with:</p>
                                <p className="pt-2 text-sm md:text-base">We can integrate with ANY dashboard of your choice</p>
                            </div>
                        </div>
                        <div className="container mx-auto px-5">
                            <div className="text-center mx-auto max-w-xs pt-10 md:mt-2 md:grid md:grid-cols-2 lg:grid-cols-4 space-y-8 md:space-y-0 md:gap-16 md:max-w-full">
                                <div>
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/shopify-logo.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Shopify Seller Dashboard"} alt={ecommercepage.cityName + " Ecommerce Shopify Seller Dashboard"} />
                                    <h3 className="font-bold text-lg py-3 uppercase">Shopify</h3>
                                </div>
                                <div>
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/snipcart.jpeg" placeholder="none" title={ecommercepage.cityName + " Ecommerce Snipcart Seller Dashboard"} alt={ecommercepage.cityName + " Ecommerce Snipcart Seller Dashboard"} />
                                    <h3 className="font-bold text-lg py-3 uppercase">Snipcart</h3>
                                </div>
                                <div>
                                    <StaticImage className="w-24 lg:w-32 mx-auto md:mt-3 lg:mt-5" src="../images/gatsby-storefront.png" placeholder="none" title={ecommercepage.cityName + " Ecommerce Gatsby Storefront Seller Dashboard"} alt={ecommercepage.cityName + " Ecommerce Gatsby Storefront Seller Dashboard"} />
                                    <h3 className="font-bold text-lg py-3 uppercase">Gatsby Storefront</h3>
                                </div>
                                <div>
                                    <StaticImage className="mb-2 w-40 lg:w-56 mx-auto" src="../images/BigCommerce.webp" placeholder="none" title={ecommercepage.cityName + " Ecommerce BigCommerce Seller Dashboard"} alt={ecommercepage.cityName + " Ecommerce BigCommerce Seller Dashboard"} />
                                    <h3 className="font-bold text-lg pt-3 uppercase">BigCommerce</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-offblue">
                    <div className="py-10 md:py-24">
                        <div className="px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase text-white mx-auto text-2xl font-bold md:text-4xl">
                                    {ecommercepage.cityName} Ecommerce Simplified
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />Let Pyxel Development help your {ecommercepage.cityName} Ecommerce business reach new heights, increase your profits, and grow
                                    your online presence. With our industry leading technologies, we can guarantee that your business will get the best quality website
                                    that it deserves, to keep your clients coming back for more!
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center pt-5 md:pt-10">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-white">
                                    <span>Get Started <b>TODAY!</b></span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default ServicePageEcommerceTemplate
export const pageQuery = graphql`
query ContentfulEcommerceBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulEcommerce( slug: {eq: $slug }) {
        title
        cityName
        boldName
        serviceType
        slug
    }
}
`